// #region FC
export enum DeliveryMethodLabel {
  PickUp = 'Pick Up',
  Ups = 'UPS',
  Courier = 'Courier',
  InternalCourier = 'Courier - Internal',
  ExternalCourier = 'Courier - External',
  FedEx = 'FedEx',
  Usps = 'USPS',
}

export enum FCStatus {
  required = 4001,
  completed = 4002,
  canceled = 4101,
  in_progress = 4007,
  needs_review = 4008,
  in_progress_waiting_for_patient_response = 4009,
  in_progress_call_patient = 4010,
  in_progress_patient_response_ready = 4011,
  in_progress_secure_link_expired = 4012,
}

export enum DeliveryMethodValue {
  PickUp = 1,
  Ups = 2,
  Courier = 3,
  FedEx = 5,
  Usps = 6,
  InternalCourier = 100,
  ExternalCourier = 101,
}

export enum UpsPackagingTypes {
  UpsLetter = 1,
  CustomerSuppliedPackage = 2,
  Pak = 3,
  UPSExpressBox = 4,
  SmallExpressBox = 5,
  MediumExpressBox = 6,
  LargeExpressBox = 7,
}

export enum OrderMethod {
  PickUp = 1,
  Delivery = 2,
}

export enum FulfillmentStatus {
  Complete = 1,
  Partial = 2,
  CantFulfill = 3,
}

export enum AncillarySupplyValues {
  SharpsContainer = 'sharps_container',
  AlcoholPreps = 'alcohol_preps',
  AdhesiveBandages = 'adhesive_bandages',
  PillOrganizer = 'pill_organizer',
  AdherencePackaging = 'adherence_packaging',
  EasyOpenCaps = 'easy_open_caps',
  GlucoseLogs = 'glucose_logs',
  OralSyringe = 'oral_syringe',
  MedicineDispensingCup = 'medicine_dispensing_cup',
  NotNeeded = 'not_needed',
}
// #endregion FC

// #region CSL
export enum CounselingMethod {
  InPerson = 1,
  OnThePhone = 2,
  ChartReview = 3,
}

export enum CounselingTaskType {
  Initial = 1,
  Ongoing = 2,
  CheckIn = 3,
}
// #endregion CSL

// #region RS
export enum RsCancelReasons {
  OutsideProvider = 'outside_provider',
  NotTargetedTherapy = 'not_targeted_therapy',
  IcdCorrection = 'icd_correction',
}
// #endregion RS

export enum PhoneUseEnum {
  Mobile = 'Mobile',
  Business = 'Business',
  Home = 'Home',
}

export enum MobilePhoneSmsEnum {
  OptIn = 'Opt in',
  OptOut = 'Opt out',
  NotSpecified = 'Not Specified',
}

// TODO: We should not have a hard coded list of role names, since these may change at any time
export enum UserRoles {
  SuperUser = 'superuser',
  GlobalAdmin = 'global_admin',
  CredentialedLiaison = 'credentialed_liaison',
  NonCredentialedLiaison = 'non_credentialed_liaison',
  SiteManager = 'site_manager',
  CorporateAdmin = 'corporate_admin',
  CredentialedPharmacist = 'credentialed_pharmacist',
  NonCredentialedPharmacist = 'non_credentialed_pharmacist',
  PharmacistCOM = 'pharmacist_com',
  NonPharmacistCOM = 'non_pharmacist_com',
  NonPharmacistGM = 'non_pharmacist_gm',
  PharmacyIntern = 'pharmacy_intern',
  PharmacistClinic = 'pharmacist_clinic',
  PharmacistCentralOps = 'pharmacist_central_ops',
  PharmacistCentralSupport = 'pharmacist_central_support',
  PharmacistDispensing = 'pharmacist_dispensing',
  LiaisonClinic = 'liaison_clinic',
  LiaisonCentralOps = 'liaison_central_ops',
  LiaisonDispensing = 'liaison_dispensing',
  LiaisonTrainer = 'liaison_trainer',
  LiaisonAT = 'liaison_at',
  ManagerAT = 'manager_at',
}

export enum MedicationStatus {
  Active = 'active',
  Canceled = 'canceled',
  Completed = 'completed',
  Deleted = 'deleted',
  Discontinued = 'discontinued',
  EnteredInError = 'entered-in-error',
  Intended = 'intended',
  OnHold = 'on-hold',
  Other = 'other',
  Stopped = 'stopped',
  NoGo = 'no-go',
}

export enum InterventionSeverityEnum {
  High = 3,
  Medium = 2,
  Low = 1,
}

export enum CustomerBranchTrellisTherapeuticClassPriority {
  High = 2,
  Medium = 1, // Not used today in DB, but leave it here for future
  Low = 0,
}

export enum ClinicalSupportOptInReasonLabel {
  ProviderReferredIntoCdm = 'Provider Referred into CDM',
}

export enum ClinicalSupportOptInReasonValue {
  ProviderReferredIntoCdm = 1,
}

export enum ClinicalSupportOptOutReasonLabel {
  PatientPerceptionTherapyControl = 'Patient Perception – Therapy Control',
  PatientPerceptionLongTimeUse = 'Patient Perception – Long Time Use',
  TimeConstraint = 'Time Constraint',
  ClinicianRecommendationComplexity = 'Clinician Recommendation - Complexity',
  Other = 'Other',
  CdmGraduationClinicianDecision = 'CDM Graduation - Clinician Decision',
  CdmGraduationCriteriaMet = 'CDM Graduation - Criteria Met',
  NoLongerDispensing = 'No longer dispensing with us - RS only',
  CdmEnrollmentCriteriaNotMet = 'Clinical Criteria Not Met',
  NotBeingSeenAtOurClinic = 'Not being seen at our clinic',
  PatientIsUnreachable = 'Patient is unreachable',
  NotClinicallyMonitoredTherapyAtThisSite = 'Not a clinically monitored therapy at this site',
}

export enum ClinicalSupportOptOutReasonValue {
  PatientPerceptionTherapyControl = 1,
  PatientPerceptionLongTimeUse = 2,
  TimeConstraint = 3,
  ClinicianRecommendationComplexity = 4,
  Other = 5,
  CdmGraduationClinicianDecision = 6,
  CdmGraduationCriteriaMet = 7,
  CdmEnrollmentCriteriaNotMet = 8,
  NoLongerDispensing = 9,
  NotBeingSeenAtOurClinic = 10,
  PatientIsUnreachable = 11,
  NotClinicallyMonitoredTherapyAtThisSite = 12,
}

export enum ClinicalSupportStatusLabel {
  Undecided = 'Undecided',
  OptIn = 'Opt in',
  OptOut = 'Opt out',
}

export enum ClinicalSupportStatusValue {
  UndecidedNotYetOffered = 1,
  OptIn = 2,
  OptOut = 3,
  UndecidedDeferredDecision = 4,
}

export enum CommunicationType {
  InPerson = 1,
  Email = 2,
  Sms = 3,
  Phone = 4,
  Letter = 5,
  Fax = 6,
  Ehr = 7,
}

export enum NoteBarTrigger {
  Contact = 'contact',
  Notes = 'notes',
  Sms = 'sms',
}

// Lookup Insurance Types
export enum LookupInsuranceTypeEnum {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
  Quarternary = 4,
}

export enum MedicationReferralStatus {
  New = 1,
  Existing = 2,
}

export enum ContactMethodTypes {
  Phone = 1,
  Email = 2,
  SMS = 3,
  EHRMessage = 4,
}

export enum TherapyTypes {
  NonSpecialty = 0,
  Specialty = 1,
  ChronicDiseaseManagement = 2,
  SpecialtyLite = 3,
  SpecialtyProviderAdministered = 4,
}

export enum AllergyStatusEnum {
  Active = 1,
}

export enum DispensingStatusValue {
  UndecidedNotYetOffered = 1,
  OptIn = 2,
  OptOut = 3,
  UndecidedDeferredDecision = 4,
}

export enum FailedTherapies {
  Category = 'other',
  Id = '346',
}

export enum OutreachServiceEnrollment {
  Liason = 12,
  Pharmacist = 18,
}

export enum OutreachTypes {
  Liason = 1,
  Pharmacist = 2,
  LiasonSubtype = 3,
  PharmacistSubtype = 4,
}

// #region Helpers
export const isClinicalSupportUndecided = (status: ClinicalSupportStatusValue): boolean =>
  [
    ClinicalSupportStatusValue.UndecidedNotYetOffered,
    ClinicalSupportStatusValue.UndecidedDeferredDecision,
  ].includes(status);

export const isDispensingUndecided = (status: DispensingStatusValue): boolean =>
  [
    DispensingStatusValue.UndecidedNotYetOffered,
    DispensingStatusValue.UndecidedDeferredDecision,
  ].includes(status);

export const isDispensingOptInOrOptOut = (status: DispensingStatusValue): boolean =>
  [DispensingStatusValue.OptIn, DispensingStatusValue.OptOut].includes(status);
// #endregion

export enum LookupResourceTypes {
  Ar = 1,
  Csl = 2,
  Dc = 3,
  Dur = 4,
  Fa = 5,
  Fc = 6,
  Fdc = 7,
  Int = 8,
  Mr = 9,
  Or = 10,
  Pa = 11,
  Qre = 12,
  Tpr = 13,
  Patient = 14,
  TherapyAdministration = 15,
  TherapyEnrollment = 16,
  PatientContact = 17,
  PatientPreference = 18,
  PharmacyInsurance = 19,
  MedicalInsurance = 20,
  FinancialAssistance = 21,
  Income = 22,
  Allergy = 23,
  PatientProblem = 24,
  PatientSpecialPopulation = 25,
  Therapy = 26,
  Medication = 27,
  Document = 28,
  Note = 29,
  PatientAddress = 31,
  Rs = 30,
  PatientEmail = 32,
  PatientPhone = 33,
  PatientContactAddress = 34,
  PatientContactEmail = 35,
  PatientContactPhone = 36,
}

export const TherapyWorkflowLabel = {
  1: 'SP',
  2: 'RS',
};

export enum FinancialAssistanceTypes {
  CopayCard = 'Manufacturer Copay Card', // active
  Pap = 'PAP', // not active
  Mpap = 'Manufacturer Prescription Assistance Program (Free Drug)', // active
  Foundation = 'Foundation', // not active
  InternalFoundation = 'Internal Foundation (Health System)', // active
  ExternalFoundation = 'External Foundation', // active
}
export enum FinancialAssistanteStatus {
  Active = 1,
  Inactive = 0,
}

export enum FinancialAssistanceSubTypes {
  CopayCard_PharmacyBenefit = 1001,
  CopayCard_CreditCard = 1002,
  CopayCard_Check = 1003,
  Pap_FreeDrug = 2001,
  Pap = 2002,
  Foundation_PharmacyBenefit = 3001,
  Foundation_CreditCardt = 3002,
  Foundation_Check = 3003,
  InternalFoundation_PharmacyBenefit = 3004,
  InternalFoundation_AccountPayable = 3010,
  ExternalFoundation_PharmacyBenefit = 3007,
  ExternalFoundation_CreditCardt = 3008,
  ExternalFoundation_Check = 3009,
}

export enum CopayCardTypes {
  pharmacyBenefit = 'Pharmacy Benefit',
  creditCard = 'Credit Card',
  check = 'Check',
  accountPayable = 'Account Payable',
}

export enum PrescriptionAssistanceProgramTypes {
  manufacturerPrescriptionAssistanceProgram = 'Manufacturer Prescription Assistance Program (Free Drug)',
}

export enum TaskTypesEnum {
  PA = 'PA',
  FA = 'FA',
  TPR = 'TPR',
  MR = 'MR',
  BI = 'BI',
  CA = 'CA',
  CI = 'CI',
  FC = 'FC',
  FDC = 'FDC',
  RS = 'RS',
  DC = 'DC',
  INT = 'INT',
  CSL = 'CSL',
  OR = 'OR',
  AR = 'AR',
  QRE = 'QRE',
  DUR = 'DUR',
  SMS = 'SMS',
  PATIENT = 'patient',
}

export enum PatientStatusEnum {
  OnRecord = 11001,
  Active = 11002,
  Inactive = 11003,
  Deceased = 11004,
}

export enum LookupNoteTagType {
  Patient = 1,
  Therapy = 2,
  Clinical = 3,
  Demographic = 4,
  Financial = 5,
  Medical_Insurance = 6,
  PBM_Insurance = 7,
  Income = 8,
  Contact = 9,
  BI = 10,
  PA = 11,
  FA = 12,
  CA = 13,
  CI = 14,
  FC = 15,
  TPR = 16,
  DC = 17,
  FDC = 18,
  INT = 19,
  CSL = 20,
  AR = 21,
  OR = 22,
  MR = 23,
  Document = 24,
  QRE = 25,
  DUR = 26,
  RS = 27,
}

export enum CourierType {
  Internal = 0,
  External = 1,
}

export enum FcQuestionsEnum {
  welcome_kit_sent = 'Welcome kit required? *',
  is_notice_privacy_practices_sent = 'Privacy notice required? *',
  patient_missed_doses = 'Has the patient missed any doses of medication? *',
  new_allergies = 'Does the Patient have any new Allergies? *',
  side_effects = 'Has the patient experienced any side effects since last time we spoke? *',
  changes_in_medication = 'Are there any Changes to Patient Medications? *',
  patient_questions = 'Does the patient have any questions for the pharmacist? *',
  new_medical_conditions = 'Does the patient have any new medical conditions? *',
  hospital_visit = 'Has the patient been hospitalized or gone to the emergency department since we last spoke? *',
  new_infections = 'Has the patient experienced any infections since we last spoke? *',
  rems_authorization_code = 'REMS Dispense Authorization Code',
}

export enum RSTypeEnum {
  Primary = 1,
  Secondary = 2,
  Tertiary = 3,
}

export enum SpecialtyTherapiesWf {
  SP = 'SP',
  RS = 'RS',
}
