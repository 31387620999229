import { assignKeys, processPhoneData } from 'services/utils/demographic-service';
import { getFilteredList, convertFilteredAddresses } from 'services/utils/task-service';
import { SmsStatus } from 'interfaces/redux/IPatient';
import { isVirtualCard } from 'containers/tasks/fill-coordination/payment-methods/payment-method-utils';
import { MED_SYNC_UPDATE_SUCCESS } from 'containers/patient/med-sync/med-sync-actions';
import moment from 'moment';
import {
  SELECT_PATIENT_SUCCESS,
  EDIT_PATIENT,
  ADD_PATIENT,
  BULK_TASKS_UPDATED,
  DISCHARGE_PATIENT,
  REENROLL_PATIENT,
  UPDATE_PATIENT_PREFERENCE_SUCCESS,
  SELECTED_PATIENT_ID,
  CLEAR_PATIENT_DATA,
  UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS,
  UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS,
  VERIFY_SPECIAL_POPULATIONS,
  RESOLVE_RECONCILIATION_SUCCESS,
  ADD_THERAPY,
  ADD_THERAPY_AR,
  EDIT_THERAPY,
  DUR_FETCH_HISTORY_SUCCESS,
  ADD_VACCINE_TO_PATIENT,
  SEND_PATIENT_OPT_IN_SMS,
  MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS,
  MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS,
  MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS,
  MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS,
  MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS,
  FETCH_PATIENT_PROBLEMS_SUCCESS,
  ADD_PROBLEM_SUCCESS,
  EDIT_PROBLEM_SUCCESS,
  UPDATE_PROBLEMS_VERIFICATION_STATUS,
  FETCH_PATIENT_SMS_MESSAGES_SUCCESS,
  UPDATE_SMS_MESSAGE_TAGS_SUCCESS,
  SEND_SMS_TO_PATIENT_SUCCESS,
  MARK_SMS_AS_READ_SUCCESS,
  MARK_SMS_AS_UNREAD_SUCCESS,
  FETCH_PATIENT_ADHERENCE_DATA_SUCCESS,
  FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS,
  CLEAR_PATIENT_ADHERENCE_DATA,
  FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS,
  LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS,
  SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS,
  UPDATE_PATIENT_PROPERTIES,
  CLEAR_DUPLICATE_PROFILE_INFORMATION,
  ADDED_PAYMENT_METHOD,
  REFRESH_PATIENT_PHONES_SUCCESS,
  REFRESH_PATIENT_QUESTIONS_SUCCESS,
  FETCH_PATIENT_METRICS_SUCCESS,
} from '../constants';

const initialState = {
  filteredAddresses: [],
  filteredEmails: [],
  filteredPhones: [],
  paymentMethodDrafts: [],
};

const convertPatientData = patient => ({
  ...initialState,
  ...patient,
  addresses: assignKeys(patient.addresses),
  emails: assignKeys(patient.emails),
  phones: processPhoneData(assignKeys(patient.phones)),
  filteredAddresses: convertFilteredAddresses(patient.addresses),
  filteredEmails: getFilteredList(assignKeys(patient.emails)),
  filteredPhones: processPhoneData(getFilteredList(assignKeys(patient.phones))).sort(
    (a, b) => a?.rank - b?.rank,
  ),
});

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CLEAR_PATIENT_DATA:
      return { needs_reconciliation: false };
    case SELECTED_PATIENT_ID:
      return { ...state, id: payload };
    case SELECT_PATIENT_SUCCESS: {
      // eslint-disable-next-line camelcase
      const existingSmsMessages = state?.sms_messages ? { sms_messages: state.sms_messages } : {};
      const newPatientState = convertPatientData(payload.patient || {});
      const newTotalPatientState = { ...existingSmsMessages, ...newPatientState };
      return newTotalPatientState;
    }
    case ADD_PATIENT:
      if (payload.data) {
        return payload.data.added_patient;
      }
      return state;
    case EDIT_PATIENT:
      if (payload.data && payload.data.updated_patient) {
        return convertPatientData(payload.data.updated_patient);
      }
      return state;
    case SEND_PATIENT_OPT_IN_SMS: {
      if (payload && payload.data) {
        return {
          ...state,
          filteredPhones: payload.data.phones
            .filter(p => !p.deleted)
            .sort((a, b) => a?.rank - b?.rank),
        };
      }
      return state;
    }

    case REFRESH_PATIENT_PHONES_SUCCESS: {
      const newPhones = {
        phones: processPhoneData(assignKeys(payload?.phones || [])),
        filteredPhones: processPhoneData(getFilteredList(assignKeys(payload?.phones || []))).sort(
          (a, b) => a?.rank - b?.rank,
        ),
      };

      return { ...state, ...newPhones };
    }

    case REFRESH_PATIENT_QUESTIONS_SUCCESS: {
      return { ...state, ...payload };
    }

    case UPDATE_PATIENT_PREFERENCE_SUCCESS:
      if (payload.data && payload.data.updated_patient_preference) {
        const { updated_patient_preference: preference } = payload.data;
        return {
          ...state,
          preferred_contact_id: preference.preferred_contact_id,
          preferred_contact_method: preference.preferred_contact_method,
          contact_method_note: preference.contact_method_note,
          preferred_rx_delivery_contact_id: preference.preferred_rx_delivery_contact_id,
          preferred_rx_delivery_method: preference.preferred_rx_delivery_method,
          preferred_rx_delivery_pharmacy_id: preference.preferred_rx_delivery_pharmacy_id,
          preferred_rx_delivery_entity_key: preference.preferred_rx_delivery_entity_key,
          preferred_rx_delivery_patient: preference.preferred_rx_delivery_patient,
          rx_delivery_note: preference.rx_delivery_note,
          patient_preferences_updated: preference.updated,
          payment_methods: preference.payment_methods,
        };
      }
      return state;
    case DISCHARGE_PATIENT:
      if (payload.data && payload.data.updated_patient) {
        return {
          ...state,
          ...convertPatientData(payload.data.updated_patient),
        };
      }
      return state;
    case REENROLL_PATIENT: {
      const { clinicId, clinicName, updatedPatient } = payload;
      const clinics = state.clinics || [];
      return {
        ...state,
        clinics: [...clinics, { id: clinicId, name: clinicName, updated_patient: updatedPatient }],
        status: updatedPatient?.status,
        status_id: updatedPatient?.status_id,
      };
    }
    case UPDATE_MEDICATION_VERIFICATION_STATUS_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          medications_verification_status_id:
            payload.data.verification_status.verification_status_id,
          medications_verification_verified_by: payload.data.verification_status.verified_by,
          medications_verification_verified_dt: payload.data.verification_status.verified_dt,
          medications_confirmed_with_emr:
            payload.data.verification_status.medications_confirmed_with_emr,
        };
      }
      return state;
    case UPDATE_ALLERGY_VERIFICATION_STATUS_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          allergies_verification_status_id: payload.data.verification_status.verification_status_id,
          allergies_verification_verified_by: payload.data.verification_status.verified_by,
          allergies_verification_verified_dt: payload.data.verification_status.verified_dt,
          allergies_confirmed_with_emr:
            payload.data.verification_status.allergies_confirmed_with_emr,
        };
      }
      return state;
    case VERIFY_SPECIAL_POPULATIONS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          special_populations_verification_status_id:
            payload.data.verification_status.verification_status_id,
          special_populations_verification_verified_by:
            payload.data.verification_status.verified_by,
          special_populations_confirmed_with_patient:
            payload.data.verification_status.special_populations_confirmed_with_patient,
          special_populations_confirmed_with_patient_date:
            payload.data.verification_status.special_populations_confirmed_with_patient_date,
          special_populations_verification_verified_dt:
            payload.data.verification_status.verified_dt,
        };
      }
      return state;
    case MARK_EMR_CONFIRMED_ALLERGIES_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          allergies_confirmed_with_emr:
            payload.data.verification_status.allergies_confirmed_with_emr,
        };
      }
      return state;
    case MARK_EMR_CONFIRMED_MEDICATIONS_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          medications_confirmed_with_emr:
            payload.data.verification_status.medications_confirmed_with_emr,
        };
      }
      return state;

    case MARK_PATIENT_CONFIRMED_ALLERGIES_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          allergies_confirmed_with_patient:
            payload.data.verification_status.allergies_confirmed_with_patient,
          allergies_confirmed_with_patient_date:
            payload.data.verification_status.allergies_confirmed_with_patient_date,
        };
      }
      return state;
    case MARK_PATIENT_CONFIRMED_MEDICATIONS_SUCCESS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          medications_confirmed_with_patient:
            payload.data.verification_status.medications_confirmed_with_patient,
          medications_confirmed_with_patient_date:
            payload.data.verification_status.medications_confirmed_with_patient_date,
        };
      }
      return state;
    case MARK_PATIENT_CONFIRMED_SPECIAL_POPULATIONS:
      if (payload.data && payload.data.verification_status) {
        return {
          ...state,
          special_populations_confirmed_with_patient:
            payload.data.verification_status.special_populations_confirmed_with_patient,
          special_populations_confirmed_with_patient_date:
            payload.data.verification_status.special_populations_confirmed_with_patient_date,
        };
      }
      return state;
    case RESOLVE_RECONCILIATION_SUCCESS:
      if (payload) {
        return {
          ...state,
          needs_reconciliation: payload.needs_reconciliation,
        };
      }
      return state;
    case ADD_THERAPY_AR:
    case ADD_THERAPY:
    case EDIT_THERAPY:
    case BULK_TASKS_UPDATED:
      if (payload && payload.data) {
        const {
          patient,
          added_problems: addedProblems,
          updated_problems: updatedTherapyProblems,
          should_unverify_problems_list: shouldUnverifyProblemsList,
        } = payload.data;
        const updatedTasksProblems = payload.data.updated_tasks?.flatMap(
          updatedTask => updatedTask.updatedProblems ?? [],
        );
        const updatedProblems = (updatedTherapyProblems ?? []).concat(updatedTasksProblems ?? []);
        let newState = { ...state };
        if (patient) {
          newState = {
            ...newState,
            clinics: patient.clinics,
            status: patient.status,
            status_id: patient.status_id,
          };
        }
        if (addedProblems && addedProblems.length > 0) {
          let newProblemsArray = [...(newState.problems || [])];
          // Replace problem with current data if existent

          addedProblems.forEach(problem => {
            const existingProblemIndex = newProblemsArray.findIndex(({ id }) => id === problem.id);
            if (existingProblemIndex >= 0) {
              newProblemsArray.splice(existingProblemIndex, 1, problem);
            } else {
              newProblemsArray = newProblemsArray.concat(problem);
            }
          });

          newState = { ...newState, problems: newProblemsArray };
        }
        if (updatedProblems && updatedProblems.length) {
          let newProblemsArray = [...(newState.problems || [])];
          updatedProblems.forEach(updatedProblem => {
            const existingProblemIdx = newProblemsArray.findIndex(
              currProblem => currProblem.id === updatedProblem.id,
            );
            if (existingProblemIdx !== -1) {
              newProblemsArray[existingProblemIdx] = updatedProblem;
            } else {
              newProblemsArray = newProblemsArray.concat(updatedProblem);
            }
          });
          newState.problems = newProblemsArray;
        }
        if (shouldUnverifyProblemsList) {
          newState.problems_verified = false;
        }
        return newState;
      }
      return state;
    case ADD_VACCINE_TO_PATIENT: {
      if (payload && payload.data) {
        return {
          ...state,
          vaccines: [...state.vaccines, payload.data],
        };
      }
      return state;
    }
    case DUR_FETCH_HISTORY_SUCCESS: {
      if (payload && payload.data) {
        return {
          ...state,
          dur: {
            history: payload.data.history,
          },
        };
      }
      return state;
    }
    case FETCH_PATIENT_PROBLEMS_SUCCESS: {
      if (payload && payload.problems) {
        return {
          ...state,
          problems: payload.problems,
        };
      }
      return state;
    }
    case ADD_PROBLEM_SUCCESS: {
      if (payload && payload.problem) {
        return {
          ...state,
          problems: [...(state.problems || []), payload.problem],
        };
      }
      return state;
    }
    case EDIT_PROBLEM_SUCCESS: {
      if (payload && payload.problem) {
        const currentProblems = [...(state.problems || [])];
        const editedProblemIndex = currentProblems.findIndex(
          problem => problem.id === payload.problem.id,
        );

        if (editedProblemIndex !== -1) {
          currentProblems[editedProblemIndex] = payload.problem;
          return {
            ...state,
            problems: currentProblems,
            problems_verified:
              payload.shouldUnverifyProblemsList === true ? false : state.problems_verified,
          };
        }
      }
      return state;
    }

    case UPDATE_PROBLEMS_VERIFICATION_STATUS: {
      return {
        ...state,
        problems_verified: payload.verified,
        problems_verified_by: payload.verifiedBy,
        problems_verified_dt: payload.verifiedDateTime,
      };
    }

    case FETCH_PATIENT_SMS_MESSAGES_SUCCESS: {
      return {
        ...state,
        sms_messages: payload.smsMessages,
      };
    }

    case UPDATE_SMS_MESSAGE_TAGS_SUCCESS: {
      const currentSmsMessages = state.sms_messages;
      for (let i = 0; i < currentSmsMessages.length; i += 1) {
        if (currentSmsMessages[i].id === payload.smsId) {
          currentSmsMessages[i].tags = payload.tags;
          break;
        }
      }

      return {
        ...state,
        sms_messages: currentSmsMessages,
      };
    }

    case SEND_SMS_TO_PATIENT_SUCCESS: {
      return {
        ...state,
        sms_messages: [payload.sentMessage, ...(state.sms_messages || [])],
      };
    }

    case MARK_SMS_AS_READ_SUCCESS: {
      const messageIndex = state.sms_messages.findIndex(message => message.id === payload.smsId);
      const messages = [...state.sms_messages];

      if (messageIndex !== -1) {
        messages[messageIndex].status = SmsStatus.Read;
        messages[messageIndex].updatingUser = payload.userDisplayName;
      }

      return {
        ...state,
        sms_messages: messages,
      };
    }

    case MARK_SMS_AS_UNREAD_SUCCESS: {
      const messageIndex = state.sms_messages.findIndex(message => message.id === payload.smsId);
      const messages = [...state.sms_messages];

      if (messageIndex !== -1) {
        messages[messageIndex].status = SmsStatus.Received;
      }

      return {
        ...state,
        sms_messages: messages,
      };
    }

    case FETCH_PATIENT_ADHERENCE_DATA_SUCCESS: {
      return {
        ...state,
        adherenceData: payload,
      };
    }

    case FETCH_PATIENT_ADHERENCE_HISTORY_SUCCESS: {
      return {
        ...state,
        adherence_history_data: payload,
      };
    }

    case CLEAR_PATIENT_ADHERENCE_DATA: {
      return {
        ...state,
        adherence_history_data: null,
      };
    }

    case SET_REPORT_DUPLICATE_PROFILE_BUTTON_STATUS: {
      return {
        ...state,
        possibleDuplicateProfiles: {
          ...state.possibleDuplicateProfiles,
          enableReportIssueButton: false,
          lastReportedBy: payload.displayName,
        },
      };
    }

    case CLEAR_DUPLICATE_PROFILE_INFORMATION: {
      return {
        ...state,
        possibleDuplicateProfiles: {},
      };
    }

    case FETCH_PATIENT_ADHERENCE_COMPLIANCE_STATUS_SUCCESS: {
      return {
        ...state,
        adherence_compliance_status: payload.adherence_compliance_status,
      };
    }
    case LOAD_USER_CUSTOM_LIST_FOR_PATIENTS_PATIENT_SUCCESS: {
      return state;
    }
    case UPDATE_PATIENT_PROPERTIES:
      if (payload.values) {
        return {
          ...state,
          ...payload.values,
        };
      }
      return state;

    case ADDED_PAYMENT_METHOD:
      if (payload.added_payment_method) {
        if (isVirtualCard(payload.added_payment_method)) {
          return state;
        }
        return {
          ...state,
          payment_methods: (state.payment_methods ?? []).concat(payload.added_payment_method),
        };
      }
      return state;

    case MED_SYNC_UPDATE_SUCCESS:
      return {
        ...state,
        anchor_date: payload.formValues.anchorDate
          ? moment(payload.formValues.anchorDate).toISOString()
          : null,
        sync_time_frame: payload.formValues.syncTimeFrame,
      };

    // eslint-disable-next-line no-fallthrough

    case FETCH_PATIENT_METRICS_SUCCESS:
      return {
        ...state,
        metrics: payload.metrics,
      };
    default:
      return state;
  }
};
